<template>
  <div class="card-row card-4">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209133932393-bg4.jpg" alt="" />
    <div class="card-intro" v-if="data.timeOfMaxExpend">
      <div class="time-text">
        <p class="intro">在<span class="bold">{{mDays}}</span></p>
        <div class="intro">
          你花费了今年<div class="label-rows">
            <i class="label"></i><span class="label-value">最多的一笔</span></div>是
          </div>
        <p class="intro"><span class="bold">{{data.maxExpend || 0}}元</span></p>
      </div>
      <p class="intro intro-text">
        那一天你做了什么重要的决定呢？
      </p>
      <div class="line"></div>
    </div>
    <div class="card-intro" v-else>
      <p class="intro">今年都花在哪里啦？</p>
      <p class="intro">你还记得吗？小小盘点一下吧~</p>
      <p class="intro">量入为出，理性消费，</p>
      <p class="intro">相信我们的人生才会更加自由哟~</p>
      <div class="line"></div>
    </div>
    <div class="card-desc" v-if="data.timeOfMaxExpend">
      <p>这是最平凡的一天呐~</p>
      <p>你还记得吗？</p>
    </div>
    <div class="card-desc" v-else>
      <p>在新的一年里，</p>
      <p>我们要共同努力呀！</p>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209134719687-sub4.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard4',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mDays() {
      const { timeOfMaxExpend } = this.data;
      let str = '';
      if (timeOfMaxExpend) {
        let time = new Date(timeOfMaxExpend);
        let m = time.getMonth() + 1;
        let d = time.getDate();
        m = m < 10 ? ('0' + m) : m;
        d = d < 10 ? ('0' + d) : d;
        str = `${m}月${d}日`;
        // let arr = timeOfMaxIncome.split('-');
        // console.log('arr', arr);
      }
      return str;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
.card-4 {
}
</style>
