<!-- eslint-disable no-return-assign -->
<template>
  <div class="card-row card-8">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209140801780-bg8.jpg" alt="" />
    <div class="card-intro" v-if="sliderNum === 10">
      <div class="time-text">
        <p class="intro">时间飞逝，转眼又到年末啦，</p>
        <p class="intro">2022年，你总计</p>
      </div>
      <p class="intro intro-text">
        收入<span class="bold">{{ data.totalIncome || 0 }}元</span>
      </p>
      <p class="intro intro-text">
        支出<span class="bold">{{data.totalExpend || 0}}元</span></p>
      <div class="line" v-if="data.totalExpend"></div>
    </div>
    <div class="card-intro" v-else>
      <div class="time-text">
        <p class="intro">一日三餐，一年四季，</p>
        <p class="intro">
          小鱼儿已经陪伴<span class="label-value"><i class="label-line"></i>1500万+</span></p>
        <p class="intro">的用户记录他们的生活，</p>
        <p class="intro">也有越来越多的新用户加入到鱼宝儿大家庭~</p>
      </div>
      <div class="line"></div>
    </div>
    <div class="card-desc" v-if="sliderNum !== 10">
      <p>记好账，理好财，</p>
      <p>我们才会有更多面对未来的勇气~</p>
      <p>未来的日子里，我们相互鼓气，一同加油！</p>
    </div>
    <div class="card-btn" v-if="sliderNum !== 10"
      @click="jumpAction">抽取我的年度关键词</div>
    <div class="card-pie" v-if="sliderNum === 10 && consumeList.length">
      <div class="consume-cons">
        <p class="head-title">消费TOP5</p>
        <div class="consume-data">
          <div class="list"
            v-for="(item, index) in consumeList"
            :key="index">
            <span class="consume-frame"
              :style="{ backgroundColor: item.color }"></span>
            <p class="consume-name">{{item.name}}</p>
          </div>
        </div>
      </div>
      <div class="consume-result">
        <div class="pie" ref="pie" v-if="isAnimate"></div>
        <span class="value">2022</span>
      </div>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209144101968-sub8.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard6',
  data() {
    return {
      consumeList: [
      ],
    };
  },
  computed: {
    colors() {
      return ['#FF8519', '#FF4C7A', '#504CFF', '#00BAFF', '#1ADD68'];
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    sliderNum: {
      type: Number,
      default: 10,
    },
    isAnimate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'data.totalIncome': function (val) {
    },
    isAnimate(val) {
      const { totalIncome } = this.data;
      let { expendBillTypeTop5s } = this.data;
      let consumeList = [];
      expendBillTypeTop5s.forEach((item, index) => {
        consumeList.push({
          name: item.key,
          value: item.value,
          color: this.colors[index],
        });
      });
      this.consumeList = consumeList;
      if (val && consumeList.length) {
        this.$nextTick(() => {
          this.initData();
        });
      }
    },
  },
  mounted() {},
  methods: {
    initData() {
      const myChart = window.echarts.init(this.$refs.pie);
      const { consumeList } = this;
      let data = consumeList.map((item) => ({ ...item, itemStyle: { color: item.color } }));
      const option = {
        tooltip: {
          trigger: 'item',
          show: false,
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '100%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data,
          },
        ],
      };
      myChart.setOption(option);
    },
    jumpAction() {
      window?._monitor('track', 'cqndgjcan_click', {
        eventName: '抽取年度关键词按钮点击',
      });
      this.$router.push({
        path: '/activity/annual/result',
        query: {
          isEmpty: 1,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';

.card-row {
  .card-pie {
    margin: 60px 120px 0 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .consume-result {
      width: 296px;
      height: 296px;
      background-color: #FFFFFF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      position: relative;
      z-index: 20;
      .value {
        font-size: 35px;
        font-weight: 800;
        color: #154E6C;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .pie {
      width: 260px;
      height: 260px;
    }
  }
  &.card-8 {
    .card-image-row {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      margin-left: 0;
      .card-image {
        width: 100%;
      }
    }
  }
}
.consume-cons {
  position: relative;
  z-index: 20;
  .head-title {
    font-size: 30px;
    font-weight: bold;
    color: #154E6C;
    line-height: 57px;
  }
  .consume-data {
    margin-top: 25px;
    .list {
      display: flex;
      align-items: center;
      .consume-frame {
        width: 15px;
        height: 15px;
        flex: 0 0 auto;
      }
      .consume-name {
        font-size: 27px;
        font-weight: 500;
        color: #154E6C;
        line-height: 57px;
        margin-left: 22px;
      }
    }
  }
}
</style>
