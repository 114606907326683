<template>
  <div class="slider-footer">
      <div class="mark">
        <div class="search-icon">
          <img
src="//download-cos.yofish.com/yofish-gongjushiyebu/20221208171314587-search-icon.png" alt="">
        </div>
        <span class="text">有鱼记账之旅</span>
        <i class="line"></i>
        <span class="serial">{{index+1}}/{{sliderNum}}</span>
      </div>
      <div class="slider-desc" v-show="sliderNum !== (index+1)">上滑查看下一页</div>
      <div class="slider-icon" v-show="sliderNum !== (index+1)">
        <img
src="//download-cos.yofish.com/yofish-gongjushiyebu/2022120817434784-slider-icon.png" alt="">
      </div>
    </div>
</template>
<script>
export default {
  name: 'CardBottom',
  props: {
    sliderNum: {
      type: Number,
      default: 3,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/index.scss';
</style>
