<template>
  <div class="annual-page">
    <div class="swiper-container">
        <div class="swiper-wrapper" ref="movePage">
          <template v-if="sliderNum === 10">
            <div class="swiper-slide annual-slider">
              <card-one :isAnimate="curIndex === 0"
                :data="sliderData" />
              <card-footer :index="0" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-two :data="sliderData" />
              <card-footer :index="1" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-three :data="sliderData" />
              <card-footer :index="2" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-four :data="sliderData" />
              <card-footer :index="3" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-five :data="sliderData" />
              <card-footer :index="4" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-six :data="sliderData" />
              <card-footer :index="5" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-seven :isAnimate="curIndex === 6"
              :data="sliderData" />
              <card-footer :index="6" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-eight
              :isAnimate="curIndex === 7"
              :data="sliderData" />
              <card-footer :index="7" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-nine :isAnimate="curIndex === 8"
              :data="sliderData" />
              <card-footer :index="8" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-ten :isAnimate="curIndex === 9"
              :data="sliderData" />
              <card-footer :index="9" :sliderNum="sliderNum"  />
            </div>
          </template>
          <template v-if="sliderNum === 3">
            <div class="swiper-slide annual-slider">
              <card-one :isAnimate="curIndex === 0"
                  :data="sliderData" :slider-num="sliderNum" />
              <card-footer :index="0" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-two :data="sliderData" :slider-num="sliderNum" />
              <card-footer :index="1" :sliderNum="sliderNum"  />
            </div>
            <div class="swiper-slide annual-slider">
              <card-eight :data="sliderData" :slider-num="sliderNum" />
              <card-footer :index="2" :sliderNum="sliderNum"  />
            </div>
          </template>
        </div>
      </div>
      <y-loading v-if="isLoading" :appendToBody="true" />
  </div>
</template>
<script>
import {
  CardOne, CardTwo, CardThree, CardFour,
  CardFive, CardSix, CardSeven, CardEight,
  CardNine, CardTen,
} from './components';
import CardFooter from './components/bottom';
import YLoading from '@/components/loading';
import { getAppStatus } from '@/utils/app';
import { getAnnualQueryApi } from '@/api/base1';

export default {
  name: 'AnnualSlider',
  components: {
    CardOne,
    CardTwo,
    CardThree,
    CardFour,
    CardFive,
    CardSix,
    CardSeven,
    CardEight,
    CardNine,
    CardTen,
    CardFooter,
    YLoading,
  },
  data() {
    return {
      // sliderList,
      curIndex: 0,
      sliderNum: '',
      sliderData: {},
      isLoading: false,
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      // return '28fdeefa-c820-4c8d-a7bd-6163eb0aab35';
      // return '4b510283-1259-429d-b5c8-4c1d9d929a58';
    },
    appName() { // app 包名
      let appInfo = '{}';
      if (window.android) appInfo = window?.android?.jzAppInfo();
      if (window.ios) appInfo = window?.ios?.jzAppInfo();
      appInfo = JSON.parse(appInfo);
      let name = appInfo.name || 'com.jz.youyu';
      return name;
    },
  },
  mounted() {
    document.title = '有鱼记账之旅';
    this.initData();
  },
  methods: {
    async initData() {
      let res;
      const { cuserId, appName } = this;
      this.isLoading = true;
      try {
        res = await getAnnualQueryApi({}, { cuserId, name: appName });
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        return this.$toast(e?.desc || '网络异常');
      }
      this.isLoading = false;
      if (res?.code === 1) {
        let { results } = res;
        let { totalExpend, totalIncome } = results;
        this.isLoading = false;
        this.sliderNum = totalIncome || totalExpend ? 10 : 3;
        // this.sliderNum = 3;
        if (results.registerDate) {
          results.registerDate = results.registerDate.replace(/-/g, '.');
        }
        if (results.totalChargeNum) {
          results.percentValue = this.getPercentvalue(results.totalChargeNum);
        }
        sessionStorage.setItem('annualData', JSON.stringify(results || {}));
        this.sliderData = results;
        this.$nextTick(() => {
          this.sliderAction();
        });
      } else {
        this.$toast(res?.desc || '网络异常');
      }
    },
    sliderAction() {
      const that = this;
      new window.Swiper('.swiper-container', {
        loop: false,
        direction: 'vertical',
        on: {
          slideChangeTransitionStart() {
            let curIndex = this.activeIndex;
            that.curIndex = curIndex;
          },
          slideChangeTransitionEnd() {},
        },
      });
    },
    getPercentvalue(totalChargeNum) {
      let value = 3;
      if (totalChargeNum >= 6 && totalChargeNum <= 50) {
        value = 25;
      }
      if (totalChargeNum >= 51 && totalChargeNum <= 100) {
        value = 50;
      }
      if (totalChargeNum >= 101 && totalChargeNum <= 300) {
        value = 80;
      }
      if (totalChargeNum >= 301 && totalChargeNum <= 1000) {
        value = 87;
      }
      if (totalChargeNum >= 1001 && totalChargeNum <= 3000) {
        value = 90;
      }
      if (totalChargeNum >= 3001 && totalChargeNum <= 6500) {
        value = 95;
      }
      if (totalChargeNum >= 6501 && totalChargeNum <= 10000) {
        value = 97;
      }
      if (totalChargeNum >= 10001) {
        value = 99;
      }
      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './styles/index.scss';
</style>
