<template>
  <div class="card-row card-10">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209172911552-bg10.jpg" alt="" />
    <div class="card-intro">
      <div class="time-text">
        <p class="intro">鱼龄 <span class="bold"> {{ data.registerOfFormat }}</span></p>
        <p class="intro">记账流水<span class="bold">{{data.totalChargeNum}}条</span></p>
        <div class="intro"><div class="label-rows">
          <i class="label label-10"></i>
          <span class="label-value">
            最高连续</span></div>记账天数<span class="bold"> {{data.longestAccountDay || 0}}天</span></div>
      </div>
      <div class="line"></div>
    </div>
    <div class="card-desc">
      <p>坚持不易，</p>
      <p>记账这件小事，</p>
      <p>你做的很好。</p>
    </div>
    <div class="card-btn animate" @click="jumpAction">抽取我的年度关键词</div>
    <div class="card-image-row" @click="isAnimate = !isAnimate">
      <div class="card-image" :class="{ animation: isAnimate }">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209173410456-sub10.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard10',
  data() {
    return {
    };
  },
  props: {
    isAnimate: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    jumpAction() {
      window?._monitor('track', 'cqndgjcan_click', {
        eventName: '抽取年度关键词按钮点击',
      });
      this.$router.push('/activity/annual/result');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';

.card-row.card-10 {
  .card-image-row {
    position: absolute;
    z-index: 10;
    width: 100%;
    left: 0;
    bottom: 0;
    margin-left: 0;
    .card-image {
      width: 100%;
    }
  }
  .animation {
    animation: translate 1s;
  }
}
@keyframes translate {
  0% {
    transform: translateY(500px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
