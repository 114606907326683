<template>
  <div class="card-row card-9">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/202212131419570-bg9.jpg" alt="" />
    <div class="card-intro">
      <div class="time-text">
        <p class="intro">在过去的一年里，你努力赚钱，认真生活，</p>
        <p class="intro">做的很不错呐</p>
        <div class="intro">
          也记得要<div class="label-rows">
          <i class="label label-9"></i><span class="label-value">@你的好朋友</span></div>，好闺蜜哟~</div>
        <p class="intro">一同走在富足的小路上！</p>
      </div>
      <div class="line"></div>
    </div>
    <div class="card-desc">
      <p>那么在新的一年里，你又有什么期许呢？</p>
      <p>现在就许下一个小目标吧，</p>
      <p>我们一起向前进！</p>
    </div>
    <!-- <div class="curve">
      <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209163758986-line.png" alt="" />
      <div class="paper-plane">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209171403730-plane.png" alt="" />
      </div>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209162653448-sub9.png" alt="" />
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'StatementCard9',
  data() {
    return {
    };
  },
  props: {
    isAnimate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
.card-row {
  &.card-9 {
    .card-image-row {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 10;
      margin-left: 0;
      .card-image {
        width: 100%;
      }
    }
  }
  .curve {
    position: absolute;
    width: 347px;
    right: 120px;
    bottom: 180px;
    z-index: 50;
    .paper-plane {
      position: absolute;
      width: 191px;
      bottom: 0px;
      right: -60px;
      transform: rotate(-150deg);
      z-index: 20;
      &.animate {
        animation: run-right-right 1s .4s 1 linear,
      run-right-top 3s .4s 1 cubic-bezier(.15, .76, .25, .86);
      animation-fill-mode: forwards;
      }
    }
  }
}
@keyframes run-right-top {
  0% {
    bottom: -10px;
  }
  100% {
    bottom: 120px;
  }
}
@keyframes run-right-right {
  0% {
    right: 40px;
  }
  100% {
    right: 120px;
  }
}
</style>
