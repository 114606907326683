<template>
  <div class="card-row card-3">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221208163337470-bg3.jpg" alt="" />
    <div class="card-intro" v-if="data.timeOfMaxIncome">
      <div class="time-text">
        <p class="intro"><span class="bold">{{mDays}}</span>这一天，</p>
        <p class="intro">你还记得吗？</p>
      </div>
      <p class="intro intro-text">
        进账了<span class="bold">{{data.maxIncome}}元</span>
      </p>
      <div class="line"></div>
    </div>
    <div class="card-intro" v-else>
      <p class="intro">在赚钱不易的这一年里，</p>
      <p class="intro">记好账，理好财，</p>
      <p class="intro">我们才有了更多面对未来的勇气~</p>
      <div class="line"></div>
    </div>
    <div class="card-desc" v-if="data.maxIncome">
      <p>荷包满满，有没有约上三五好友，</p>
      <p>小小庆祝一下呢~</p>
    </div>
    <div class="card-desc" v-else>
      <p>那么，在新的一年里，</p>
      <p>你又有什么期许呢？</p>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209133428347-sub3.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard3',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mDays() {
      const { timeOfMaxIncome } = this.data;
      let str = '';
      if (timeOfMaxIncome) {
        let time = new Date(timeOfMaxIncome);
        let m = time.getMonth() + 1;
        let d = time.getDate();
        m = m < 10 ? ('0' + m) : m;
        d = d < 10 ? ('0' + d) : d;
        str = `${m}月${d}日`;
        // let arr = timeOfMaxIncome.split('-');
        // console.log('arr', arr);
      }
      return str;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
.card-3 {
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .card-intro {
    padding-top: 130px;
    margin: 0 66px;
    position: relative;
    z-index: 20;
    .intro {
      font-size: 30px;
      font-weight: bold;
      color: #154E6C;
      line-height: 64px;
    }
    .line {
      width: 100%;
      height: 1.5px;
      background-color: #154E6C;
      margin-top: 50px;
    }
  }
  .card-desc {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 400;
    color: #154E6C;
    line-height: 58px;
    margin-left: 74px;
    position: relative;
    z-index: 20;
  }
  .bold {
    font-size: 50px;
    font-weight: bold;
    color: #FF8519;
  }
  .card-image-row {
    position: absolute;
    left: 50%;
    margin-left: -278px;
    bottom: 190px;
    z-index: 10;
  }
  .card-image {
    width: 556px;
    margin-right: 0;
  }
  img {
    width: 100%;
  }
}
</style>
