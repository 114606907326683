<template>
  <div class="card-row card-5">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209134036710-bg5.jpg" alt="" />
    <div class="card-intro">
      <div class="time-text">
        <p class="intro" v-if="timeZoneType !== '5'"><span class="bold">好习惯</span>非常重要</p>
        <p class="intro" v-if="timeZoneType === '1'">你习惯早睡早起~</p>
        <p class="intro" v-if="timeZoneType === '5'">是只小夜猫呀~</p>
      </div>
      <p class="intro intro-text">
        你最喜欢在<span class="bold">{{timeValue}}</span>
      </p>
      <p class="intro intro-text">来有鱼记一笔</p>
      <div class="line"></div>
    </div>
    <div class="card-desc" v-if="timeZoneType === '1'">
      <p>好好生活的你在闪闪发光，</p>
      <p>勤劳的鱼宝儿最优秀啦~</p>
    </div>
    <div class="card-desc" v-if="timeZoneType === '2'">
      <p>饭后的时光，总是那么惬意~</p>
    </div>
    <div class="card-desc" v-if="timeZoneType === '3'">
      <p>孜孜不倦地记录生活，</p>
      <p>一定是个可爱的人儿~</p>
    </div>
    <div class="card-desc" v-if="timeZoneType === '4'">
      <p>记录好一天的生活，</p>
      <p>你会有一个甜甜的梦~</p>
    </div>
    <div class="card-desc" v-if="timeZoneType === '5'">
      <p>夜晚也有小鱼儿相伴，</p>
      <p>我们一起迎来崭新的一天~</p>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209135630840-sub5.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard5',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    timeValue() {
      const { timeZoneType } = this;
      let value = '早上5:00-10:00';
      if (timeZoneType === '2') {
        value = '中午10:00-14:00';
      }
      if (timeZoneType === '3') {
        value = '下午14:00-18:00';
      }
      if (timeZoneType === '4') {
        value = '傍晚18:00-22:00';
      }
      if (timeZoneType === '5') {
        value = '夜间22:00-早上5:00';
      }
      return value;
    },
    timeZoneType() {
      return this.data.timeZoneType;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
.card-5 {
  .card-intro {
    padding-top: 130px;
    margin: 0 66px;
    position: relative;
    z-index: 20;
    .intro {
      font-size: 30px;
      font-weight: bold;
      color: #154E6C;
      line-height: 64px;
    }
    .line {
      width: 100%;
      height: 1.5px;
      background-color: #154E6C;
      margin-top: 50px;
    }
  }
  .card-desc {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 400;
    color: #154E6C;
    line-height: 58px;
    margin-left: 74px;
    position: relative;
    z-index: 20;
  }
  .bold {
    font-size: 50px;
    font-weight: bold;
    color: #FF8519;
  }
  .card-image-row {
    position: absolute;
    left: 50%;
    margin-left: -336px;
    bottom: 190px;
    z-index: 10;
  }
  .card-image {
    width: 672px;
    margin-right: 0;
  }
  img {
    width: 100%;
  }
}
</style>
