<template>
  <div class="card-row card-2">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221208163300704-bg2.jpg" alt="">
    <div class="card-intro" v-if="sliderNum === 10">
      <p class="intro-text">
        在有鱼陪伴你的这 <span class="bold">{{data.registerOfFormat}}</span>里
      </p>
      <p class="intro-text">
        你一共记账 <span class="bold">{{data.totalChargeNum}}</span> 次
      </p>
      <p class="intro-text">
        <i class="label"></i>
        <span class="label-value">超过了</span>全国<span class="bold">{{percentValue}}%</span>的鱼宝儿~
      </p>
      <div class="line"></div>
    </div>
    <div class="card-intro" v-else>
      <p class="intro-text">
        一转眼，马上就到年末啦，
      </p>
      <p class="intro-text">
        这一年你有<span class="label-value"><i class="label-line"></i>哪些收获</span>呢~
      </p>
      <p class="intro-text">
        细细品味，
      </p>
      <p class="intro-text">
        其实我们也收获了许多细碎的幸福呐~
      </p>
      <div class="line"></div>
    </div>
    <div class="card-desc" v-if="sliderNum === 10">
      <p>每一次记录，都是生活点滴的缩影，</p>
      <p>小鱼儿也一定会给你用心珍藏~</p>
    </div>
    <div class="card-desc" v-else>
      <p>当然，新的一年小鱼儿也一定会，</p>
      <p>陪伴你一起记录生活~ 拉钩钩，</p>
      <p>我们不见不散哟~</p>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209111526150-sub2.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard2',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    sliderNum: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    percentValue() {
      return this.data.percentValue;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
.card-2 {
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  // background-image: url('http://download-cos.yofish.com/yofish-gongjushiyebu/20221208163300704-bg2.jpg');
}
</style>
