<template>
  <div class="card-row card-7">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209140649316-bg7.jpg" alt="" />
    <div class="card-intro">
      <div class="time-text">
        <p class="intro" v-if="data.member">@<span class="bold">{{data.member}}</span></p>
        <p class="intro" v-if="!data.member">已经有100万+个家庭在使用共享账本啦，</p>
        <p class="intro" v-if="!data.member">快@对你最重要的那个TA</p>
        <div class="intro" v-if="data.member">是对你<div class="label-rows">
          <i class="label label-7"></i><span class="label-value">很重要</span></div>的人</div>
      </div>
      <div class="line"></div>
    </div>
    <div class="card-desc" v-if="data.member">
      <p>你们一起记账，共同规划着未来的幸福生活~</p>
      <p>请一起坚定地走下去吧！</p>
    </div>
    <div class="card-desc" v-else>
      <p>来一起记账！</p>
      <p>共同构筑未来的美好生活吧~</p>
    </div>
    <div class="card-image-row">
      <div class="card-man-image" :class="{ animate: isAnimate }">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209141901302-man.png" alt="" />
      </div>
      <div class="card-woman-image" :class="{ animate: isAnimate }">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/2022120914192357-woman.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard6',
  props: {
    isAnimate: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
.card-7 {
  .card-intro {
    padding-top: 130px;
    margin: 0 66px;
    position: relative;
    z-index: 20;
    .intro {
      font-size: 30px;
      font-weight: bold;
      color: #154E6C;
      line-height: 64px;
    }
    .line {
      width: 100%;
      height: 1.5px;
      background-color: #154E6C;
      margin-top: 50px;
    }
  }
  .card-desc {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 400;
    color: #154E6C;
    line-height: 58px;
    margin-left: 74px;
    position: relative;
    z-index: 20;
  }
  .bold {
    font-size: 50px;
    font-weight: bold;
    color: #FF8519;
  }
  .card-man-image {
    width: 490px;
    position: absolute;
    left: -60px;
    bottom: 0;
    &.animate {
      animation: manAnimate 1s ease;
    }
  }
  .card-woman-image {
    width: 427px;
    position: absolute;
    right: 60px;
    bottom: 50px;
    &.animate {
      animation: womanAnimate 1.5s ease;
    }
  }
  img {
    width: 100%;
  }
}
.card-7 {
  .card-image-row {
    position: absolute;
    bottom: 160px;
    z-index: 10;
    width: 100%;
  }
}
@keyframes manAnimate {
  0% {
    transform: translate(-100px, 600px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes womanAnimate {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
