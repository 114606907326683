<template>
  <div class="card-row card-1">
    <div class="card-content">
      <div class="title">嗨~</div>
      <p class="name">亲爱的鱼宝儿</p>
      <p class="card-text text-1" v-if="sliderNum === 10">
        在<span class="bold">{{data.registerDate}}</span>的这一天
      </p>
      <p class="card-text text-1" v-if="sliderNum === 3">
        你是否还记得 <span class="bold">{{data.registerDate}}</span>
      </p>
      <template v-if="sliderNum === 10">
        <p class="card-text text-2">你加入鱼宝儿大家庭啦~</p>
        <div class="card-desc">
          <p>
          今天是你与有鱼记账相伴的第 <span class="bold">{{data.registerDays}}</span> 天</p>
        <p>未来的日子里，我们也会相互陪伴呀~</p>
        </div>
      </template>
      <template v-if="sliderNum === 3">
        <div class="card-desc">
          <p>是什么机缘巧合让我们有了第一次相遇呢？</p>
          <p>
            那么，在未来的日子里，我们也会相互陪伴呀~</p>
        </div>
      </template>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209104256196-sub1.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard1',
  props: {
    isAnimate: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    sliderNum: {
      type: Number,
      default: 10,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
@import '@/assets/scss/animate.scss';
.card-row {
  background: url('//download-cos.yofish.com/yofish-gongjushiyebu/202212081631571-bg1.jpg');
}
.card-content {
  margin: 0 66px 0 66px;
  padding-top: 130px;
  position: relative;
  z-index: 20;
  .title {
    font-size: 91px;
    font-weight: bold;
    color: #FF8519;
    line-height: 128px;
    display: inline-block;
    &.animate {
      animation: left-right .5s ease-in;
    }
  }
  .name {
    font-size: 35px;
    font-weight: 500;
    color: #154E6C;
    line-height: 64px;
    padding-bottom: 20px;
    border-bottom: 1.5px solid #154E6C;
  }
  .card-text {
    font-size: 30px;
    font-weight: bold;
    color: #154E6C;
    line-height: 64px;
  }
  .text-1 {
    margin-top: 35px;
  }
  .text-2 {
    margin-top: 10px;
  }
  .bold {
    font-size: 50px;
    color: #FF8519;
    font-weight: bold;
  }
  .card-desc-1 {
    margin-top: 30px;
  }
  .card-desc {
    font-size: 28px;
    font-weight: 400;
    color: #154E6C;
    line-height: 64px;
    margin-left:0;
  }
}
.card-row.card-1 .card-image-row {
  position: absolute;
  right: 0;
  bottom: 195px;
  z-index: 10;
  width: 90%;
  margin-left: 0;
  right: 0;
  left: auto;
  .card-image {
    margin-right: 0;
    margin: auto;
    width: 100%;
  }
}

img {
  width: 100%;
}
@keyframes rolleIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
            transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
</style>
