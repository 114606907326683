<template>
  <div class="card-row card-6">
    <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209134248121-bg6.jpg" alt="" />
    <div class="card-intro">
      <div class="time-text">
        <p class="intro">你平均
          <span class="bold">每月记账{{data.monthlyAccountDay >= 18 ? 28 : 8 }}天</span></p>
        <div class="intro">
          <div class="label-rows" v-if="data.monthlyAccountDay >= 18">
            <i class="label"></i><span class="label-value">记账小天才</span>非你莫属啦~
          </div>
         </div>
        </div>
      <div class="line"></div>
    </div>
    <div class="card-desc" v-if="data.monthlyAccountDay >= 18">
      <p>你总是孜孜不倦地记录你的生活，</p>
      <p>认真生活的人儿最可爱~</p>
    </div>
    <div class="card-desc" v-else>
      <p>花的明白，记得及时，</p>
      <p>下一个记账小天才非你莫属啦~</p>
    </div>
    <div class="card-image-row">
      <div class="card-image">
        <img src="//download-cos.yofish.com/yofish-gongjushiyebu/20221209140206693-sub6.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatementCard6',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/card.scss';
.card-6 {
  .card-intro {
    padding-top: 130px;
    margin: 0 66px;
    position: relative;
    z-index: 20;
    .intro {
      font-size: 30px;
      font-weight: bold;
      color: #154E6C;
      line-height: 64px;
    }
    .line {
      width: 100%;
      height: 1.5px;
      background-color: #154E6C;
      margin-top: 50px;
    }
  }
  .card-desc {
    margin-top: 50px;
    font-size: 28px;
    font-weight: 400;
    color: #154E6C;
    line-height: 58px;
    margin-left: 74px;
    position: relative;
    z-index: 20;
  }
  .bold {
    font-size: 50px;
    font-weight: bold;
    color: #FF8519;
  }
  .card-image-row {
    position: absolute;
    left: 50%;
    margin-left: -336px;
    bottom: 190px;
    z-index: 10;
  }
  .card-image {
    width: 672px;
    margin-right: 0;
  }
  img {
    width: 100%;
  }
}
</style>
